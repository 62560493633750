<template>
    <div>
        <div class="buttons width-container pd pdb0">
            <sc-button @click="generateCodes = true" icon="plus">
                Generate new batch (Excel)
            </sc-button>
        </div>
        <div class="width-container">
            <sc-table :table="table">
                <template #column-assigned="{row}">
                    <sc-table-cell :table="table" field="assigned" :clickable="false">
                        <awesome-icon icon="check" v-if="row.occupied"/>
                    </sc-table-cell>
                </template>
                <template #column-KP="{row}">
                    <sc-table-cell :table="table" field="KP" :clickable="false">
                        {{ getPlayerMetaValue(row, 'kp_number') }}
                    </sc-table-cell>
                </template>
                <template #column-club_name="{row}">
                    <sc-table-cell :table="table" field="club_name" :clickable="false">
                        {{ getPlayerMetaValue(row, 'Club_Name') }}
                    </sc-table-cell>
                </template>

                <template #column-actions="{row}">
                    <sc-table-cell field="actions" :table="table" v-if="row.player" :padded="false">
                        <template #actions>
                            <sc-table-button icon="eye" @click="view(row)" style="margin-right: 0.5em;"/>
                            <sc-table-button-confirm icon="unlink" @onClick="detach(row)" action="detach"/>
                        </template>
                    </sc-table-cell>

                </template>
            </sc-table>
        </div>

        <sc-modal v-if="generateCodes" title="Generate new batch (Excel)" @close="generateCodes = false">
            <label>
                Amount to generate
                <input v-model="amountToGenerate" type="number" min="1" max="500" step="1"/>
            </label>
            <br/>
            <br/>
            <sc-button @click="generateBatch" :disabled="!amountToGenerate || amountToGenerate > 500"
                       :running="processing" icon="download" color="blue">
                <span v-if="!processing">
                    Generate batch & download
                </span>
                <span v-else>
                    Generating codes...
                </span>
            </sc-button>
        </sc-modal>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "index",
    data() {
        return {
            generateCodes: false,
            amountToGenerate: undefined,
            processing: false,
            table: this.$stable.createTable({
                url: '/corporate-qr',
                sortBy: 'assigned',
                sortDir: 'desc',
                columns: {
                    id: {fill: true,},
                    assigned: {
                        target: 'occupied',
                    },
                    KP: {
                        sort: false,
                    },
                    club_name: {
                        sort: false
                    },
                    actions: {width: 100, sort: false}
                },
            }),
        }
    },

    mounted() {
        this.table.fetch();
    },

    methods: {
        view(row) {
            this.$router.push(`/manager/player/${row.player.cm_player_uuid}/traffic`)
        },

        getPlayerMetaValue(row, metaName) {
            if (row.player && row.player.metadata) {
                return row.player.metadata.find((m) => m.name === `Player.${metaName}`).value;
            }
        },

        detach(row) {
            let payload = {
                "cqr": row.player.corporate_qr_id,
                "player_uuid": row.player.cm_player_uuid
            }

            this.$talker.api.put('/corporate-qr/detach-from-player', payload)
                .then((res) => {
                    this.$notify.success("Successfully detached QR code");
                    this.table.fetch();
                })
                .catch((err) => {
                    this.$notify.error("Unable to detach QR code");
                })
        },

        generateBatch() {
            this.processing = true;

            this.$talker.api.post('/corporate-qr/new-batch', {amount: this.amountToGenerate}, {
                    responseType: 'blob'
                })
                .then((res) => {
                    let filename = 'vw-corporate-qr-codes' + '_' + moment().format('Y-MM-DD_HHmm') + '.xlsx';
                    let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.$nextTick(() => {
                        this.$notify.success(`${this.amountToGenerate} new QR Codes generated`);
                        this.amountToGenerate = undefined;
                        this.generateCodes = false;
                        this.table.fetch();
                        this.processing = false;
                    })
                })
                .catch((e) => {
                    this.$notify.error(`Failed to generate ${this.amountToGenerate} QR codes`)
                    this.processing = false;
                })
        }
    }
}
</script>

<style scoped>

</style>
