<template>
    <div class="date-picker">
        <date-range-picker class="picker"
                           v-model="pickedDate"
                           :ranges="false"
                           :locale-data="locale"
                           :opens="opens"
                           :singleDatePicker="true"
                           :timePicker="false"
                           :showWeekNumbers="false"
                           :showDropdowns="false"
        >

        </date-range-picker>
    </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import moment from 'moment-timezone'
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';

export default {
  name: "sc-datepicker-single",

  components: {DateRangePicker},

  props: {
    date: {
      default: () => {
        return {
          startDate: moment().format('LL'),
          endDate: moment().format('LL'),
        }
      }
    }
  },

  data() {
    return {
      opens: "right", // which way the picker opens, default "center", can be "left"/"right"
      locale: {
        direction: 'ltr', // direction of text
        format: 'DD-MM-YYYY', // format of the dates displayed
        separator: ' to ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(), // array of days - see moment documentation for details
        monthNames: moment.monthsShort(), // array of month names - see moment documentation for details
        firstDay: 1, // ISO first day of week - see moment documentation for details
        showWeekNumbers: false // show week numbers on each row of the calendar
      },
    }
  },

  computed: {
      pickedDate: {
          get() {
              return this.date;
          },
          set(value) {
              this.$emit('update:date', value)
          }
      },
  },

  mounted() {

  },

  methods: {}
}
</script>

<style lang="scss">

.date-picker {
    display: flex;
    align-items: center;

    .label {
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: 500;
        margin-bottom: 0.3em;
    }

    .picker {
        display: block;
        text-align: center;
        font-size: 0.9em;
    }

    .form-control {
        background: #fff;
        border: none;
        box-shadow: $shading;
        border-radius: 0.3em;
        padding: 15px 20px;
        font-weight: 500;
        min-width: 250px;
    }

    .daterangepicker.show-ranges {
        min-width: 720px;
        color: #333;
    }

    .reportrange-text {
        width: auto;
    }

    .days-count {
        margin-left: 32px;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 0.8em;
        color: rgba(0, 0, 0, 0.7);
    }
}
</style>
