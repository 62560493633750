<template>
    <div>
        <div tabindex="0"
             class="switch"
             @click.stop="$emit('update:field', !field)"
             v-on:keyup.enter="$emit('update:field', !field)"
             v-on:keyup.left="$emit('update:field', false)"
             v-on:keyup.right="$emit('update:field', true)"
             :style="colors"
             :class="{true:isActive, false: !isActive, ready}">
            <div class="switch-button"></div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'sc-field-boolean',
    props: ['field', 'colorTrue', 'colorFalse'],

    data() {
      return {
        ready: false,
      }
    },

    computed: {
      isActive() {
        if (this.field === 1 || this.field === true) {
          return true;
        }
      },
      colors() {
        if (this.isActive && this.colorTrue) return {background: this.colorTrue};
        if (!this.isActive && this.colorFalse) return {background: this.colorFalse};
        return;
      }
    },
    mounted() {
      setTimeout(() => {
        this.ready = true;
      }, 300)
    }
  }
</script>

<style scoped lang="scss">

    .switch {
        margin-top: 2px;
        background: #4c6a8a;
        cursor: pointer;
        color: #fff;
        display: inline-block;
        height: 26px;
        width: 53px;
        position: relative;
        border-radius: 100px;
        box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
        outline: none;
    }

    .switch.ready {
        transition: background .4s cubic-bezier(0, 0, 0.26, 0.99);
    }

    .switch:focus {
        /*box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3), 0 0 5px 2px $color-tertiary;*/
    }

    .switch.false {
        background: #e4574e;
    }

    .switch.true {
        background: #54a858;
    }

    .switch .switch-button {
        height: 20px;
        width: 20px;
        top: 3px;
        border-radius: 100px;
        position: absolute;
        left: 30px;
        background: #fff;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
        overflow: hidden;
    }

    .switch.ready .switch-button {
        transition-property: left, background;
        transition-duration: 0.4s, 0.1s;
        transition-timing-function: cubic-bezier(0, 0, 0.26, 0.99), ease-in-out;
    }

    .switch .switch-button:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0%;
        bottom: 0%;
        background: linear-gradient(rgba(0, 0, 100, 0.4), rgba(0, 0, 0, 0), rgba(0, 0, 100, 0.3));
        border-radius: 100px;
        opacity: 0.8;
        animation: spinToWin 1s linear infinite;
        animation-play-state: paused;
        transition: opacity .2s ease-in-out;
    }

    .switch:focus .switch-button:after {
        opacity: 1;
        animation-play-state: running;
    }

    @keyframes spinToWin {
        100% {
            transform: rotate(360deg)
        }
    }

    .switch:active .switch-button {
        background: #eee;
    }

    .switch.false .switch-button {
        left: 3px;
    }

</style>