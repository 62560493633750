<template>
    <div class="chart">
        <div class="pdt graph-container">
            <div>
                <canvas ref="graph" width="400" height="200"></canvas>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment-timezone'
import Chart from 'chart.js'

export default {
    name: "chart-interactions",

    props: {
        dateRange: {},
        dataSets: {},
    },

    data() {
        return {
            records: [],
            graph: undefined,

            config: {
                type: 'line',
                data: {
                    datasets: [],
                },
                options: {
                    legend: {
                        display: false
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        }
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        displayColors: false,
                        callbacks: {
                            title: function (tooltipItems, data) {
                                //Return value for title
                                let date = moment(new Date(tooltipItems[0].xLabel));
                                return [date.format('DD/MM/YYYY')];
                            },
                            label: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                                let value = parseFloat(tooltipItem.yLabel).toFixed(0) || '';
                                return label + ': ' + value;
                            }
                        }
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: true
                    },
                    scales: {
                        xAxes: [{
                            type: 'time',
                            distribution: 'linear',
                            time: {
                                unit: 'hour',
                                displayFormats: {
                                    hour: 'H:mm'
                                }
                            },
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                fontColor: '#555',
                                callback: function (value, index, values) {
                                    if (values[index]) {
                                        var date = moment(values[index].value);
                                    } else {
                                        return value;
                                    }
                                    if (date.format('H') === '0') {
                                        return [value, date.format('ddd')];
                                    } else {
                                        return value;
                                    }
                                },
                                stepSize: 10,
                            },
                        }],
                        yAxes: [{
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                fontColor: '#555',
                                min: 0,
                                fontSize: 10,
                                callback: function (value, index, values) {
                                    if (index % 2 === 0) {
                                        if (value > 0 && value < 1) {
                                            return parseFloat(value).toFixed(2);
                                        }
                                        return parseFloat(value).toFixed(0);
                                    }
                                }
                            },
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
            },
        }
    },

    watch: {
        dataSets() {
            this.setData()
        },
    },

    mounted() {
        this.$nextTick(function () {
            this.graph = new Chart(this.$refs.graph, this.config);
            this.setData()
        });
    },

    methods: {
        setData() {

            let start = moment.tz(this.dateRange.startDate, 'UTC').startOf('day');
            let end = moment.tz(this.dateRange.endDate, 'UTC').endOf('day');
            let diff = end.diff(start, 'days');
            this.config.options.scales.xAxes[0].time.unit = (diff > 1) ? 'day' : 'hour';

            this.config.data.datasets = this.dataSets;

            this.graph.update();
        },
    }
}
</script>

<style scoped lang="scss">
.chart {
    background: #fff;
    box-shadow: $shading;
    border-radius: 0.5em;
}

</style>


<style lang="scss">

</style>
