<template>
    <div class="pd width-container">
        <sc-form @submit="post()" :form="form">

            <template #field-role_id="{field}">
                <sc-form-field field="role_id" :form="form">
                    <select v-model="form.fields.role_id">
                        <option :value="1">Admin</option>
                        <option :value="3">Support</option>
                        <option :value="4">Customer</option>
                    </select>
                </sc-form-field>

                <div class="notify">
                    User receives automatic invitation through email
                </div>
            </template>

        </sc-form>


    </div>
</template>

<script>

export default {
  name: 'new',
  data() {
    return {
      form: this.$sform.createForm({
        name: 'New User',
        fields: {
          name: '',
          email: '',
          role_id: 1,
        },
        url: `users`,
        success: () => {
          this.$notify.success('user added')
          this.$router.push('/users')
        }
      }),

    }
  },

  mounted() {

  },

  methods: {
    post() {
      this.form.post()
    }
  }
}
</script>

<style scoped>
.notify {
    padding: 0em 0 2em;
    font-weight: 500;
    font-size: 0.9em;
}
</style>