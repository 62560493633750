<template>
    <div>
        <select v-model="amount" @change="change" class="amount-selector">
            <option v-for="o in options" :key="o" :value="o">{{ o }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "amount-selector",
    props: {
        ident: {required: true}
    },
    data() {
        return {
            options: [25, 50, 100],
            amount: 25
        }
    },

    mounted() {
      if (localStorage.getItem(this.ident)) {
          this.amount = parseInt(localStorage.getItem(this.ident));
      }
        this.$emit('changed', this.amount)
    },

    methods: {
        change() {
            localStorage.setItem(this.ident, this.amount);
            this.$emit('changed', this.amount);
        }
    }
}
</script>

<style scoped>
.amount-selector {
    padding: 7px 8px;
}
</style>
