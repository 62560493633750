<template>
    <div class="date-picker">
        <date-range-picker class="picker"
                           v-model="rangeBetween"
                           :ranges="ranges"
                           :locale-data="locale"
                           :opens="opens"
        ></date-range-picker>
        <div class="days-count" v-if="daysCount">
            {{ daysBetween }}
            <template v-if="daysBetween === 1">day</template>
            <template v-else>days</template>
        </div>
    </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import moment from 'moment-timezone'
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';

export default {
  name: "sc-datepicker",

  components: {DateRangePicker},

  props: {
    dateRange: {
      default: () => {
        return {
          startDate: moment().subtract('1', 'month'),
          endDate: moment(),
        }
      }
    },
    daysCount: {
      default: true
    }
  },

  data() {
    return {

      opens: "right", // which way the picker opens, default "center", can be "left"/"right"
      locale: {
        direction: 'ltr', // direction of text
        format: 'DD-MM-YYYY', // format of the dates displayed
        separator: ' to ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(), // array of days - see moment documentation for details
        monthNames: moment.monthsShort(), // array of month names - see moment documentation for details
        firstDay: 1, // ISO first day of week - see moment documentation for details
        showWeekNumbers: true // show week numbers on each row of the calendar
      },
      ranges: { // default value for ranges object (if you set this to false ranges will no be rendered)
        'Last 24 hours': [moment(), moment()],
        'Last 48 hours': [moment().subtract(1, 'days'), moment()],
        'Last 7 days': [moment().subtract(6, 'days'), moment()],
        'Last 30 days': [moment().subtract(29, 'days'), moment()],
        'Last 3 months': [moment().subtract(3, 'months'), moment()],
      },
    }
  },

  computed: {
    rangeBetween: {
      get() {
        return this.dateRange;
      },
      set(value) {
        this.$emit('update:dateRange', value)
      }
    },
    daysBetween() {
      return Math.abs(moment(this.dateRange.startDate).diff((moment(this.dateRange.endDate).endOf('day')), 'days')) + 1;
    },
  },

  mounted() {

  },

  methods: {}
}
</script>

<style lang="scss">

.date-picker {
    display: flex;
    align-items: center;

    .label {
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: 500;
        margin-bottom: 0.3em;
    }

    .picker {
        display: block;
        text-align: center;
        font-size: 0.9em;
    }

    .form-control {
        background: #fff;
        border: none;
        box-shadow: $shading;
        border-radius: 0.3em;
        padding: 15px 20px;
        font-weight: 500;
        min-width: 250px;
    }

    .daterangepicker.show-ranges {
        min-width: 720px;
        color: #333;
    }

    .reportrange-text {
        width: auto;
    }

    .days-count {
        margin-left: 32px;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 0.8em;
        color: rgba(0, 0, 0, 0.7);
    }
}
</style>