<template>
    <sc-table style="padding:0;" :table="table" @view="view" :clickable="clickable" :loading="loading">
        <template #header>
            <div class="table-header custom-header">
                {{table.name}}
                <amount-selector ident="top-players" @changed="$emit('amountChanged', $event)"/>
            </div>
        </template>
    </sc-table>
</template>

<script>
export default {
    name: "chart-top-players",

    props: {
        topPlayers: {},
        columns: {required: true},
        clickable: {required: true, type: Boolean},
        loading: {required: false, default: 0, type: Number}
    },

    data() {
        return {
            table: this.$stable.createTable({
                name: 'top players',
                sortBy: 'total',
                columns: this.columns
            })
        }
    },

    watch: {
        topPlayers(newValue, oldValue) {
            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                this.update();
            }
        },
    },

    mounted() {
        this.update();
    },

    methods: {
        update() {
            if (this.topPlayers) {
                this.table.data = this.topPlayers;
                this.table.hasData = true;
                this.table.prefetch();
            }
        },

        view(row) {
            this.$emit('view', row)
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .total .top {
    font-weight: 700;
}

.custom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
