<template>
    <div class="pdw pdh">
        <div class="panel pdh pdw">
            <div class="overview-url" @click="copyToClipboard" v-if="isConnected">
                <sc-button class="copy" icon="copy">Copy QR url</sc-button>
                {{ player.corporate_qr.url }}
            </div>
            <div v-else>
                No QR code assigned to this connection.
            </div>
        </div>
        <br />
        <div class="panel pdh pdw" v-if="isConnected">
            <div class="overview-url detach" >
                <sc-table-button-confirm action="detach" icon="unlink" @onClick="detach" />
                Detach QR
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "club-qr",
    computed: {
        ...mapGetters({
            player: 'getCurrentPlayer'
        }),
        isConnected() {
            return !['', undefined, null].includes(this.player.corporate_qr)
        },
        canCopy() {
            return navigator.clipboard !== undefined
        }
    },
    methods: {
        copyToClipboard() {
            if (this.canCopy) {
                navigator.clipboard.writeText(this.player.corporate_qr.url)
                    .then(() => {
                        this.$notify.success('Copied to clipboard')
                    })
            }
        },

        detach() {
            let payload = {
                "cqr": this.player.corporate_qr_id,
                "player_uuid": this.player.cm_player_uuid
            }

            this.$talker.api.put('/corporate-qr/detach-from-player', payload)
                .then((res) => {
                    this.$notify.success("Successfully detached QR code");
                    this.$router.push('/manager/')
                })
                .catch((err) => {
                    this.$notify.error("Unable to detach QR code");
                })
        }
    }
}
</script>

<style scoped lang="scss">
.overview-url {
    display: flex;
    align-items: center;

    .copy {
        margin-right: 1em;
    }

    .icon {
        margin-left: 1em;
    }

    ::v-deep {
        .action-container {
            background: #ee3235;
            color: #FFF;
            border-radius: 0.2em;
            margin-right: 1em;

            &:hover {
                background:#ff6d72;
            }
        }
    }
}

.overview-qr {
    margin-top: 2em;

    .heading {
        padding: 12px 16px;
        background: #515b6b;
        color: #FFF;
        font-weight: 600;
        font-size: 14.4px;
        line-height: 22.4px;
        width: 200px;
        border-radius: 0.2em


    }
}
</style>
