<template>
    <div>
        <children-route :animate="true"></children-route>
    </div>
</template>

<script>
  export default {
    name: 'index',
  }
</script>