<template>
    <component :is="type" class="tab" :to="to" :replace="replace" @click="$emit('clicked')"
               :class="{active:isActive,stacked}" :append="append">
        <div class="tab-inner">
            <div class="icon">
                <awesome-icon :icon="icon" v-if="icon"></awesome-icon>
            </div>
            <span v-if="hasDefaultSlot"><slot></slot></span><span
                v-else>{{ name }}</span>
        </div>
    </component>
</template>

<script>
export default {
  name: "sc-tab",
  props: {
    name: {
      required: true,
      type: String,
    },
    icon: '',
    current: '',
    to: {},
    stacked: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    append: {
      type: Boolean,
      default: false,
    },
    replace: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      type: 'div'
    }
  },

  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    isActive() {
      return (this.active === true) ? true : this.current === this.name;
    }
  },

  mounted() {
    if (this.to) {
      this.type = 'router-link'
    }
  }
}
</script>

<style scoped lang="scss">
.tab {
    text-align: center;
    padding: 13px 16px 13px 16px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.7em;
    color: #6b7085;
    margin-right: 8px;
    box-sizing: border-box;
    transition-property: color;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
    position: relative;

    &:last-child {
        margin-right: 0;
    }

    &:not(.active):hover {
        color: #444;
    }

    &:after {
        content: '';
        display: block;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 4px;
        right: 4px;
        background: $color-primary;
        opacity: 0;
        transition: opacity .2s ease-in-out;
        border-radius: 5px 5px 0 0;
    }

    &.router-link-active:after,
    &.active:after {
        opacity: 1;
    }

    &.router-link-active,
    &.active {
        pointer-events: none,
    }

    .tab-inner {
        display: flex;
        align-items: center;

        .icon {
            width: 12px;
            margin-right: .8em;
            /*text-align: left;*/
            margin-top: -1px;
        }
    }
}

.stacked.tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .tab-inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
            margin: 0;
            width: auto;
        }
    }

    span {
        margin-top: 6px;
        line-height: 1.2em;
    }
}


</style>