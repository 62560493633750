<template>
    <div class="pd">
        <modal-datepicker v-if="isModalVisible" @closeIt="closeModal" :date.sync="dateSingle" @actionButtonClick="exportData" :running="exporting"/>

        <div class="page-header">
            <sc-datepicker :date-range.sync="dateRange"/>
            <sc-button @click="showModal" icon="file-export" :running="exporting">
                Export
            </sc-button>
        </div>
        <div class="pdh">
            <chart-interactions :date-range="dateRange" :dataSets="interactionsData"/>
        </div>
        <div class="top-charts">
            <chart-top-players :topPlayers="topPlayers" :columns="topPlayerColumns"
                               @view="viewPlayer" :clickable="true" :loading="topPlayersLoading"
                               @amountChanged="amountChanged('topPlayersAmount', $event)"/>
            <chart-top-media :topMedia="topMedia" :columns="topMediaColumns" :clickable="false"
                             :loading="topMediaLoading"
                             @amountChanged="amountChanged('topMediaAmount', $event)"/>
        </div>
    </div>
</template>

<script>
import ChartInteractions from "@/components/chart-interactions";
import moment from "moment";
import ScDatepicker from "@/components/sc-datepicker";
import ChartTopPlayers from "@/components/chart-top-players";
import ChartTopMedia from "@/components/chart-top-media";
import ModalDatepicker from "@/components/modal-datepicker";

export default {
    name: "home",
    components: {ChartTopMedia, ChartTopPlayers, ScDatepicker, ChartInteractions, ModalDatepicker},

    data() {
        return {
            dateRange: {
                startDate: moment().subtract('1', 'month'),
                endDate: moment(),
            },
            dateSingle: {
                startDate: moment(),
                endDate: moment(),
            },

            // Interactions chart
            mediaInteractions: undefined,
            scanInteractions: undefined,
            interactionsData: [],

            // Top Players chart
            topPlayers: undefined,
            topPlayersAmount: 25,
            topPlayersLoading: 1,
            topPlayerColumns: {
                // player_id: {name: 'id', width: 50},
                name: {fill: true},
                userScans: {name: 'scan', width: 150},
                userPlayMedia: {name: 'play media', width: 150},
                total: {width: 150}
            },

            // Top Media chart
            topMedia: undefined,
            topMediaAmount: 25,
            topMediaLoading: 1,
            topMediaColumns: {
                // media_id: {name: 'id', width: 50},
                name: {fill: true},
                total: {width: 150}
            },

            //Export
            exporting: false,
            isModalVisible: false
        }
    },
    watch: {
        dateRange() {
            this.fetchInteractions()
            this.fetchTopPlayers();
            this.fetchTopMedia();
        }
    },
    mounted() {
        // this.fetch();
        this.fetchInteractions()

    },
    methods: {
        amountChanged(key, amount) {
            this[key] = amount;
            if (key.toLowerCase().includes('players')) {
                this.fetchTopPlayers();
            } else if (key.toLowerCase().includes('media')) {
                this.fetchTopMedia()
            }
        },

        fetch() {

        },

        exportData() {
            if (!this.exporting) {
                this.exporting = true;

                this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/export`, {
                    start: moment.tz(this.dateSingle.startDate, 'UTC').startOf('day'),
                    end: moment.tz(this.dateSingle.endDate, 'UTC').endOf('day')
                }, {
                    responseType: 'blob'
                })
                    .then((res) => {
                        let filename = 'export-playback-vw_log' + '_' + moment(this.dateSingle.startDate).format('Y-MM-DD') + '.xlsx';
                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        this.$notify.success('Export complete');

                        this.exporting = false;
                    })
                    .catch((e) => {
                        console.error(e);
                        this.exporting = false;
                    })
            }
        },
        /*
         * INTERACTIONS
         */
        fetchInteractions() {
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day')
            };

            // Get All interactions
            return Promise.allSettled([this.fetchMediaInteractions(payload), this.fetchScanInteractions(payload)])
                .then(() => {
                    this.processInteractions()
                })
                .catch((error) => {
                    console.error(error);
                })


        },

        async fetchMediaInteractions(payload) {
            await this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/playback-events`, payload)
                .then(res => {
                    this.mediaInteractions = res.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchScanInteractions(payload) {
            // Get scan interactions
            await this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/interactions`, payload)
                .then(res => {
                    this.scanInteractions = res.data;
                })
                .catch((error) => {
                    throw error;
                });
        },

        processInteractions() {
            this.interactionsData = [];
            let interactionSets = [
                {
                    name: 'userScan',
                    color: '#ff4800',
                    icon: 'times',
                    active: true,
                },
                {
                    name: 'userPlayMedia',
                    color: '#4e73e2',
                    icon: 'times',
                    active: true,
                },
            ]

            for (let i = 0; i < interactionSets.length; i++) {
                let setName = interactionSets[i].name;
                let currentInteractions = setName === 'userScan' ? this.scanInteractions : this.mediaInteractions
                let data = [];

                for (let x = 0; x < currentInteractions.length; x++) {
                    let record = currentInteractions[x];
                    let startedAt = record.started_at.split('/').reverse().join('/')

                    let dataPoint = {
                        x: moment(startedAt),
                        y: record.amount
                    };
                    data.push(dataPoint);
                }

                let dataSet = {
                    label: setName,
                    data: data,
                    backgroundColor: interactionSets[i].color,
                    borderColor: interactionSets[i].color,
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1,
                };

                this.interactionsData.push(dataSet);
            }
        },

        /*
         * TOP PLAYERS
         */
        fetchTopPlayers() {
            this.topPlayersLoading = 1;
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day'),
                amount: this.topPlayersAmount
            };

            this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/top-players`, payload)
                .then(res => {
                    let topPlayers = res.data
                    delete payload.amount;
                    payload.ids = topPlayers.map((p) => p.player_id)

                    if (payload.ids.length > 0) {

                        this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/players/interactions`, payload)
                            .then((res2) => {
                                this.topPlayers = topPlayers.map((p) => {
                                    p.userScans = res2.data.find((p2) => p2.player_id === p.player_id).total;
                                    p.userPlayMedia = p.total;
                                    p.total = p.userScans + p.userPlayMedia;
                                    return p
                                })

                                this.topPlayersLoading = 0;
                            })
                            .catch((err) => {
                                this.topPlayersLoading = 0;
                                throw err;
                            })
                    } else {
                        this.topPlayersLoading = 0;
                    }
                })
                .catch((e) => {
                    this.topPlayersLoading = 0;
                    console.error(e)
                })


        },

        viewPlayer(player) {
            this.$router.push(`/manager/player/${player.uuid}/interactions`)
        },

        /*
         * TOP MEDIA
         */
        fetchTopMedia() {
            this.topMediaLoading = 1;
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day'),
                amount: this.topMediaAmount
            };

            this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/top-media`, payload)
                .then(res => {
                    this.topMedia = res.data;
                    this.topMediaLoading = 0;
                })
                .catch((e) => {
                    this.topMediaLoading = 0;
                    console.error(e)
                })
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
    }

}
</script>

<style lang="scss" scoped>
.top-charts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
        cursor: pointer;
    }
}
</style>
