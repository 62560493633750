<template>
    <div class="tabs-wrapper">
        <sc-button v-if="back" class="back" :tabInline="true" @clicked="$router.push(back)" icon="chevron-left">
            Back
        </sc-button>
        <div class="tabs" :class="{stacked,centered}">
            <slot></slot>
        </div>
    </div>

</template>

<script>
  export default {
    name: "sc-tabs",
    props: {
      stacked: {
        type: Boolean,
        default: false
      },
      back: {
        type: String
      },
      centered: {
        type: Boolean,
        default: false
      },
    }
  }
</script>

<style scoped lang="scss">
    .tabs-wrapper {
        display: flex;
        align-items: center;
    }

    .back {
        margin-right: 32px;
    }

    .back .button-container button {

    }

    .tabs {
        flex: 1;
        display: flex;
        background:#fff;
        box-shadow:$shading;
        padding: 0 0;
        box-sizing: border-box;
        border-radius: 5px;
    }

    .stacked.tabs {
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        margin-bottom: 1px;
        border-radius: 5px 5px 0 0
    }

    .centered.tabs {
        justify-content: center;
    }
</style>