import index from './index.vue'
import player from './player/routes.js'

const routes = {
  path: 'manager',
  component: index,
  meta: {name: 'connections'},
  children: [
    player,
  ]
};

export default routes;