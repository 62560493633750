<template>
    <div class="container" :style="{width:width + 'px'}" :class="{open, closed:!open}">
        <!--        <div class="close" @click="toggleNav()">-->
        <!--            <div class="nav-icon">-->
        <!--                <awesome-icon icon="chevron-left" v-show="open"></awesome-icon>-->
        <!--                <awesome-icon icon="bars" v-show="!open"></awesome-icon>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="logo" @click="$router.push('/')">
            <img v-if="open" src="@/img/logo-white.png"/>
            <img v-else src="@/img/scala-bang.png"/>
        </div>
        <div class="close" @click="toggleNav()">
            <div class="nav-icon">
                <awesome-icon icon="chevron-left" v-show="open"/>
                <awesome-icon icon="chevron-right" v-show="!open"/>
            </div>
        </div>
        <div class="nav" :style="{width:maxWidth + 'px'}">
            <div class="nav-title">
                <div class="nav-icon"><span v-if="!open">VW</span></div>
                <div class="nav-name">Virtual Workouts</div>
            </div>

            <router-link to="/" class="home">
                <div class="nav-icon">
                    <awesome-icon icon="home">/</awesome-icon>
                </div>
                <div class="nav-name">Home</div>
            </router-link>
            <router-link to="/content-managers">
                <div class="nav-icon">
                    <awesome-icon icon="tachometer-alt"/>
                </div>
                <div class="nav-name">Content Manager</div>
            </router-link>
            <router-link to="/manager">
                <div class="nav-icon">
                    <awesome-icon icon="sitemap"/>
                </div>
                <div class="nav-name">Connections</div>
            </router-link>
            <router-link to="/users" v-if="$user.isAtLeast(10)">
                <div class="nav-icon">
                    <awesome-icon icon="users"/>
                </div>
                <div class="nav-name">Users</div>
            </router-link>

            <router-link to="/lessons">
                <div class="nav-icon">
                    <awesome-icon icon="dumbbell"/>
                </div>
                <div class="nav-name">Lessons</div>
            </router-link>
            
            <router-link to="/qr">
                <div class="nav-icon">
                    <awesome-icon icon="qrcode"/>
                </div>
                <div class="nav-name">Corporate QR</div>
            </router-link>
        </div>

        <div class="nav" :style="{width:maxWidth + 'px'}">
            <div class="nav-title">
                <div class="nav-icon"><span v-if="!open">VC</span></div>
                <div class="nav-name">Virtual Cycling</div>
            </div>

            <router-link to="/vc" class="home">
                <div class="nav-icon">
                    <awesome-icon icon="home"/>
                </div>
                <div class="nav-name">Home</div>
            </router-link>
        </div>

        <div class="user" @click="logout">
            <div class="icon">
                <awesome-icon icon="sign-out-alt"/>
            </div>
            <div class="name">
                Log out
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "sc-nav",
    data() {
        return {
            open: true,
            width: 230,
            maxWidth: 230,
            minWidth: 60,
        }
    },

    created() {
        if (localStorage.getItem('nav-open') === '0') {
            this.open = false;
            this.width = 60;
        }
    },

    mounted() {
        this.$emit('setMargin', this.width)
    },
    methods: {
        toggleNav() {
            if (this.open) {
                this.open = false;
                this.width = this.minWidth;
                localStorage.setItem('nav-open', '0');
            } else {
                this.open = true;
                this.width = this.maxWidth;
                localStorage.setItem('nav-open', '1');
            }
            this.$emit('setMargin', this.width);
        },

        logout() {
            this.$talker.logout({router: this.$router})
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    position: fixed;
    left: 0;
    top: 0;
    background: #0F1C2B;
    transition: width .4s $easing;
    height: 100%;
    color: #fff;
    z-index: 5;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
    }
}

.close {
    height: 4em;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    transition: all .3s ease-in-out;

    padding-left: 0px;
}

.closed .close {
    padding-left: 0;
}

.nav {
    flex: 1;
}

.user {
    box-sizing: border-box;
    display: flex;
    height: 60px;
    align-items: center;
    transition: background .15s ease-in-out;

    &:hover {
        background: #2f435a;
        cursor: pointer;
    }

    .name {
        font-size: 0.9em;
        font-weight: 700;
        color: #fff;
        transition-property: color, transform;
        transition-timing-function: $easing;
        transition-duration: 0.6s;
        transition-delay: .10s;
        transform: translate3d(0px, 0, 0);
        z-index: 2;
    }

    .icon {
        position: relative;
        padding: 0 24px;
        width: 12px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        color: rgba(255, 255, 255, 0.8);
        z-index: 2;
    }
}

.nav a, .nav .nav-title {
    background: rgba(0, 0, 0, 0);
    transition: all .3s ease-in-out;
    text-decoration: none;
    text-transform: capitalize;
    height: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-left: 0;
}

.closed .nav a {
    padding-left: 0;
}

a:before, .nav-title:before {
    content: '';
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 1;
    width: 250%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0 2px 0 0;
    background: #2f435a;
    transform-origin: left;
    pointer-events: none;
    transition-property: opacity, transform;
    transition-timing-function: $easing, $easing;
    transition-duration: 0.6s, 0.4s;
}

a.router-link-active.home:before {
    opacity: 0;
}

a.router-link-exact-active.home:before,
a.router-link-active:before {
    opacity: 1;
}

.closed {

    a.router-link-active.home:before {
        opacity: 0;

    }

    a.router-link-exact-active.home:before,
    a.router-link-active:before {
        //left: 0;
        opacity: 1;
        transform: skewY(15deg) translateY(-7px);
    }
}

.nav-name {
    font-size: 0.9em;
    font-weight: 700;
    color: #fff;
    transition-property: color, transform;
    transition-timing-function: $easing;
    transition-duration: 0.6s;
    transition-delay: .10s;
    transform: translate3d(0px, 0, 0);
    z-index: 2;
}

.closed .nav-name {
    color: rgba(255, 255, 255, 0.0);
    transform: translate3d(-20px, 0, 0);
    transition-delay: .0s;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
}

.nav-icon {
    position: relative;
    padding: 0 24px;
    width: 12px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    color: rgba(255, 255, 255, 0.8);
    z-index: 2;
}

.nav-title {
    position: relative;

    .nav-icon {
        font-weight: 700;
    }

    &::after {
        position: absolute;
        bottom: .5em;
        display: block;
        content: '';
        background: rgba(255,255,255,0.5);
        height: 1px;
        width: 100%;
    }
}

.logo {
    padding: 37px 22px 15px;
    cursor: pointer;
    height: 40px;

    img {
        width: 100%;
        max-height: 40px;
        object-fit: contain;
    }
}

.closed .logo {
    padding-left: 15px;
    padding-right: 15px;
}
</style>
