<template>
    <div>
        <div class="width-container pd">
            <sc-form @submit="form.put()" :form="form"></sc-form>
            <sc-form @submit="passwordform.put()" :form="passwordform" style="margin-top:1em;">

                <template #field-password="{field}">
                    <sc-form-field :form="form" field="password">
                        <input type="password" v-model="passwordform.fields.password">
                    </sc-form-field>
                </template>

            </sc-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: this.$sform.createForm({
                fields: {
                    name: '',
                    url: '',
                    virtual_folder: '',
                },
                success: () => {
                    this.$notify.success('CM saved')
                },
                url: `content-manager/${this.$route.params.contentmanager_id}`,
            }),

            passwordform: this.$sform.createForm({
                fields: {
                    user_name: '',
                    password: '',
                },
                success: () => {
                    this.$notify.success('Password saved')
                },
                url: `content-manager/${this.$route.params.contentmanager_id}`,
                url_put: `content-manager/${this.$route.params.contentmanager_id}/password-update`,
            }),

        }
    },

    mounted() {
        this.form.get()
            .catch((error) => {
                if (error.response.status === 403) {
                    this.$notify.error('Not authorized');
                    this.$router.push('/')
                }
            });
        this.passwordform.get();
    },
}
</script>

<style scoped lang="scss">

</style>
