import index from './index.vue'
import home from './home.vue'

const routes = {
  path: '',
  component: index,
  children: [
    {
      path: '',
      component: home,
      meta: {
        name: 'interactions'
      }
    }
  ]
};

export default routes;
