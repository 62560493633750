<template>
    <transition name="modal-fade" appear >
    <div class="modal-backdrop">
        <div class="modal">
            <header class="modal-header">
                <h2 class="header-title">
                    <slot name="header">Title</slot>
                </h2>
                <sc-button type="button" class="btn-close" @click="close" color="white">x</sc-button>
            </header>

            <section class="modal-body">
                <slot name="body">
                    content
                </slot>
            </section>

            <footer class="modal-footer">
                <div class="modal-buttons">
                    <sc-button @click="close">Cancel</sc-button>
                    <slot name="footer-button"></slot>
                </div>
            </footer>
        </div>
    </div>
    </transition>
</template>

<script>
import ScButton from "@/components/common/sc-button";

export default {
    name: "modal",
    components: {ScButton},
    methods: {
        close() {
            this.$emit('close');
        },
    },
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1000;
}
.modal {
    width: 34vw;
    height: 44vh;
    background: white;
    border-radius: 0.5em;
    padding: 2em;
    box-shadow: 2px 2px 20px 1px rgba(0,0,0,0.1);
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.modal-header {
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.modal-body {
    height: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.header-title {
    font-size: 1.4em;
    font-weight: bold;
}
.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 1em;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .3s ease;
}
</style>
