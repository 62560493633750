var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"players"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.params.player_id),expression:"!$route.params.player_id"}],staticClass:"pd search-bar"},[_c('sc-search',{staticClass:"search",attrs:{"field":"name"},on:{"searched":_vm.search}}),_c('div',{staticClass:"toggle"},[_c('div',{staticClass:"text"},[_vm._v("Show connected")]),_c('sc-field-boolean',{attrs:{"field":_vm.show_all,"color-false":"#8e96b9"},on:{"update:field":[function($event){_vm.show_all=$event},function($event){return _vm.players.fetch()}]}}),_c('div',{staticClass:"text"},[_vm._v("Show all")])],1)],1),(!_vm.$route.params.player_id)?_c('sc-table',{attrs:{"table":_vm.players},scopedSlots:_vm._u([{key:"column-name",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"name","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_c('span',{staticClass:"player-uuid",staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(row.name))])])]}},{key:"column-session_uuid",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"session_uuid","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_vm._v(" "+_vm._s(_vm.socketPlayerValue(row, 'session_uuid', '-'))+" ")])]}},{key:"column-players",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"players","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_vm._v(" "+_vm._s(_vm.socketPlayerValue(row, 'connections', 0))+" ")])]}},{key:"column-users",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"users","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_vm._v(" "+_vm._s(_vm.socketPlayerValue(row, 'users', 0))+" ")])]}},{key:"column-browsers",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"browsers","table":_vm.players},nativeOn:{"click":function($event){return _vm.view(row)}}},[_vm._v(" "+_vm._s(_vm.socketPlayerValue(row, 'browsers', 0))+" ")])]}},{key:"column-actions",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"actions","table":_vm.players},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('sc-table-button',{staticStyle:{"margin-right":"0.5em"},attrs:{"icon":"redo"},on:{"click":function($event){return _vm.refreshSession(row.cm_player_uuid)}}}),_c('sc-table-button',{attrs:{"icon":"eye"},on:{"click":function($event){return _vm.view(row)}}})]},proxy:true}],null,true)})]}}],null,false,4179630708)}):_vm._e(),(_vm.$route.params.player_id)?_c('div',{staticClass:"player-view"},[_c('router-view',{key:_vm.$route.params.player_id,attrs:{"managerPlayer":_vm.socketPlayer()},on:{"refreshSession":_vm.refreshSession,"requestSchedule":_vm.requestSchedule}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }