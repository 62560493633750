import _index from './index.vue'
import _all from './all.vue'
import _new from './new.vue'

import _edit from './edit/routes'

const routes = {
  path: 'content-managers',
  meta: {name: 'content-manager'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'content managers'},
      component: _all,
    },
    {
      path: 'new',
      meta: {name: 'new'},
      component: _new,
    },
    _edit
  ]
};

export default routes;