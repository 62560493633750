<template>
    <div>
        <div class="buttons width-container pd pdb0">
            <sc-button icon="chevron-left" class="back" @click="$router.push('/lessons')">Back
            </sc-button>
        </div>

        <div class="pd width-container">

            <sc-form @submit="post()" :form="form"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'edit',
    data() {
        return {
            form: this.$sform.createForm({
                name: 'Edit Lesson',
                fields: {
                    name: ''
                },
                url: `lessons/${this.$route.params.lesson_id}`,
                success: () => {
                    this.$notify.success('lesson saved')
                }
            }),

        }
    },

    mounted() {
        this.form.get()
            .catch((error) => {
                if (error.response.status === 403) {
                    this.$notify.error('Not authorized');
                    this.$router.push('/')
                }
            });
    },

    methods: {
        post() {
            this.form.put()
        }
    }
}
</script>

<style scoped>
.notify {
    padding: 0em 0 2em;
    font-weight: 500;
    font-size: 0.9em;
}
</style>
