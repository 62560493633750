<template>
    <div>
        <div class="inline-buttons pd pdb0">
            <sc-button icon="plus" @click="$router.push('/users/new')">new user</sc-button>
        </div>
        <sc-table class="width-container" :table="table" @view="view"></sc-table>
    </div>
</template>

<script>

export default {
  name: "all",
  data() {
    return {
      table: this.$stable.createTable({
        url: '/users',
        sortBy: 'name',
        columns: {
          id: {width: 60},
          name: {width: 300},
          email: {
            fill: true
          },
          role: {target: 'role.name', sort: false,},
          last_login: {
            type: 'date',
          }
        },
      }),
    }
  },

  mounted() {
    this.table.fetch();
  },

  methods: {
    view(item) {
      // this.$router.push({path: `/uploads/${item.id}/details`, append: true})
    },
  }
}
</script>

<style scoped>

</style>
