<template>
    <div>
        <div class="actions pd pdb0">
            <sc-button icon="chevron-left" class="back" @click="$router.push('/manager')">Back
            </sc-button>
            <sc-button icon="redo" @click="$emit('refreshSession', player.cm_player_uuid)">
                Refresh session
            </sc-button>
            <div style="position:relative;" v-if="player && $user.isAtLeast(20)">
                <sc-button :icon="['fas', 'bug']" @click="toggleDebug">
                    {{ player.debug ? 'Disable' : 'Enable' }} Debug
                </sc-button>
                <div class="debug-help" v-if="player.debug">
                    Enabled for {{ prettyDebugTime }}
                </div>
            </div>
        </div>

        <div class="pd pdb0">
            <div class="header">
                <div class="player-info">
                    <div class="sc-widget">
                        <div class="label">
                            Player
                        </div>
                        <div class="value">
                            <span v-if="player">{{ player['name'] }}</span>
                        </div>
                    </div>
                </div>
                <div class="connections">
                    <div class="info-rows sc-widgets" v-if="managerPlayer">
                        <div class="info-row sc-widget">
                            <div class="label">
                                Players
                            </div>
                            <div class="value">
                                {{ managerPlayer['connections'] }}
                            </div>
                        </div>
                        <div class="info-row sc-widget">
                            <div class="label">
                                Users
                            </div>
                            <div class="value">
                                {{ managerPlayer['users'] }}
                            </div>
                        </div>
                        <div class="info-row sc-widget" v-if="$user.isAtLeast(20)">
                            <div class="label">
                                Browsers
                            </div>
                            <div class="value">
                                {{ managerPlayer['browsers'] }}
                            </div>
                        </div>
                    </div>
                    <div class="info-rows not-connected" v-else>
                        Not connected to websocket
                    </div>
                </div>

            </div>
        </div>

        <div class="pd pdb0">
            <sc-tabs>
                <sc-tab name="traffic" icon="sitemap" to="traffic"></sc-tab>
                <sc-tab name="interactions" icon="chart-line" to="interactions"></sc-tab>
                <sc-tab name="Club QR" icon="qrcode" to="club-qr"></sc-tab>
            </sc-tabs>
        </div>

        <router-view v-if="ready" :passed="player"></router-view>
    </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
    name: 'index',

    props: {
        managerPlayer: {}
    },

    data() {
        return {
            player: null,
            ready: false,
        }
    },
    computed: {
        prettyDebugTime() {
            if (this.player && this.player.debug) {
                let date = moment(this.player.debug_until);
                return date.fromNow(true);
            }
        }
    },

    mounted() {
        this.fetch();
    },
    beforeDestroy() {
        this.$store.commit('setCurrentPlayer', undefined);
    },

    methods: {
        fetch() {
            this.$talker.api(`player-by-uuid/${this.$route.params.player_id}`)
                .then(res => {
                    this.player = res.data;
                    this.$store.commit('setCurrentPlayer', this.player);
                    this.ready = true;
                })
        },

        toggleDebug() {
            this.$talker.api.post(`players/${this.player.id}/debug_mode`, {
                debug_mode: this.player.debug === 1 ? 0 : 1
            })
                .then(res => {
                    console.log(res.data);
                    this.player = res.data;
                })
                .catch((err) => {
                    console.log("err", err);
                })
        },
    }

}
</script>

<style scoped lang="scss">

.actions {
    display: flex;

    > div {
        margin-right: 32px;
    }
}


.header {
    display: flex;

    .player-info {
        margin-right: 32px;
    }

    .connections {

        .sc-widgets {
            background: $color-tertiary;

            .sc-widget {
                background: $color-tertiary;
                box-shadow: none;
                color: #fff;
            }
        }

        .not-connected {
            background: $color-tertiary;
            color: #fff;
            font-weight: 700;
            padding: 24px 32px;
            border-radius: 0.3em;
            box-shadow: $shading;
        }
    }
}

.debug-help {
    position: absolute;
    bottom: -2em;
    font-size: x-small;
    width: 100%;
    text-align: center;
}


</style>
