<template>
    <div>
        <sc-table-button class="delete" :icon="icon" @click.native.stop="processing = true" title="aksdfj"/>

        <sc-modal v-if="processing" @close="processing = false">
            <div class="deleting">
                <div class="info">
                    <div>
                        <slot>You are about to {{ action }} an item</slot>
                    </div>
                </div>
                <div class="buttons">
                    <sc-button style="margin-right:1em;" @click="triggerAction()">
                        Yes, {{action}}
                    </sc-button>
                    <sc-button @click="processing = false">
                        Cancel
                    </sc-button>
                </div>
            </div>
        </sc-modal>
    </div>
</template>

<script>
export default {
    name: "sc-table-button-confirm",
    props: {
        icon: {required: true, type: String},
        action: {required: true, type: String}
    },
    data() {
        return {
            processing: false,
        }
    },

    methods: {
        triggerAction() {
            this.$emit('onClick');
            this.processing = false;
        }
    }
}
</script>

<style scoped lang="scss">
.deleting .info {
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 2em;
}

.deleting .item-title {
    font-weight: 600;
}

.deleting .buttons {
    justify-content: center;
    display: flex;
}

.confirm-question {
    margin-top: 2em;
}
</style>
