import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        user
    },
    state: {
        currentPlayer: undefined
    },
    mutations: {
        setCurrentPlayer(state, player) {
            state.currentPlayer = player
        }
    },
    actions: {},
    getters: {
        getCurrentPlayer(state) {
            return state.currentPlayer;
        }
    }
})
