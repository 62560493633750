import VueRouter from 'vue-router'

import app from '../views/app/app.vue';
import contentManager from '../views/app/content-manager/routes.js';
import manager from '../views/app/manager/routes.js';
import auth from '../views/auth/routes'
import users from '../views/app/users/routes.js'
import lessons from '../views/app/lessons/routes.js'

import qr from '../views/app/corporate-qr/index.vue'

import home from '../views/app/home/routes'
import vc from '../views/app/virtual-cyling/routes'

const routes = [
    {
        path: '/',
        component: app,
        meta: {requiresAuth: true, navRoot: true},
        children: [
            home,
            contentManager,
            manager,
            users,
            lessons,
            vc,
            {
                path: 'qr',
                component: qr
            }
        ]
    },
    ...auth,
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        let cookie = (process.env.VUE_APP_COOKIE_NAME) ? process.env.VUE_APP_COOKIE_NAME : 'bearer';
        if (!localStorage.getItem(cookie)) {
            let query = to.query;
            query['redirect'] = to.path;
            next({
                path: '/login',
                query: query,
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router
