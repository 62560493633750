<template>
    <div>
        <div class="actions pd pdb0">
            <sc-button icon="chevron-left" class="back" @click="$router.push('/vc')">Back</sc-button>
        </div>

        <div class="pd pdb0">
            <div class="header">
                <div class="player-info">
                    <div class="sc-widget">
                        <div class="label">
                            Player
                        </div>
                        <div class="value">
                            <span v-if="player">{{ player['name'] }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="pd pdb0">
            <sc-tabs>
                <sc-tab name="interactions" icon="chart-line" @clicked="switchTab('interactions')" :active="tab === 'interactions'"/>
            </sc-tabs>
        </div>

        <interactions />
    </div>
</template>

<script>
import moment from "moment-timezone";
import Interactions from "./interactions";

export default {
    name: 'index',
    components: { Interactions},
    data() {
        return {
            dateRange: {
                startDate: moment().subtract('1', 'month'),
                endDate: moment(),
            },

            interactions: null,
            ready: false,
            player: {
                name: this.$route.query ? this.$route.query.name : undefined
            },
            tab: 'interactions'
        }
    },

    methods: {
        switchTab(tab) {
            this.tab = tab;
        }
    }
}
</script>

<style scoped lang="scss">

.actions {
    display: flex;

    > div {
        margin-right: 32px;
    }
}


.header {
    display: flex;

    .player-info {
        margin-right: 32px;
    }

    .connections {

        .sc-widgets {
            background: $color-tertiary;

            .sc-widget {
                background: $color-tertiary;
                box-shadow: none;
                color: #fff;
            }
        }

        .not-connected {
            background: $color-tertiary;
            color: #fff;
            font-weight: 700;
            padding: 24px 32px;
            border-radius: 0.3em;
            box-shadow: $shading;
        }
    }
}


</style>
