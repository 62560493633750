import _index from './index.vue'
import main from './main.vue'
import configs from './configs.vue'

const routes = {
  path: ':contentmanager_id/edit',
  meta: {edit: true, name: 'edit'},
  redirect: ':contentmanager_id/edit/main',
  component: _index,
  children: [
    {
      path: 'main',
      meta: {name: 'main'},
      component: main
    },
    {
      path: 'configs',
      meta: {name: 'configs'},
      component: configs
    }
  ]
};

export default routes;