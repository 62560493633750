<template>
    <div>
        <portal to="banner">
            <sc-page-header title="New Content Manager">
            </sc-page-header>
        </portal>
        <div class="buttons pd width-container pdb0">
            <sc-button class="back" @click="$router.push('/content-managers')" icon="chevron-left">Back
            </sc-button>
        </div>
        <div class="width-container pd">
            <sc-form :form="form" @submit="post">

                <template #field-password>
                    <sc-form-field field="password" :form="form">
                        <input type="password" v-model="form.fields.password"/>
                    </sc-form-field>
                </template>

            </sc-form>

            <!--            <form v-on:submit.prevent="post()" class="sc-form panel pd">-->
            <!--                <div class="header"><span>Details</span></div>-->
            <!--                <sc-loading :form="form"></sc-loading>-->
            <!--                <sc-form-field :form="form" field="name">-->
            <!--                    <input v-model="form.fields.name" ref="initialInput" required/>-->
            <!--                </sc-form-field>-->
            <!--                <sc-form-field :form="form" field="url" name="Content Manager URL"-->
            <!--                               description='Base Url, without the "/ContentManager"'>-->
            <!--                    <input v-model="form.fields.url" required/>-->
            <!--                </sc-form-field>-->
            <!--                <sc-form-field :form="form" field="virtual_folder" name="Virtual Folder"-->
            <!--                               description='e.g. "ContentManager"'>-->
            <!--                    <input v-model="form.fields.virtual_folder" required/>-->
            <!--                </sc-form-field>-->
            <!--                <sc-form-field :form="form" field="user_name">-->
            <!--                    <input v-model="form.fields.user_name" required/>-->
            <!--                </sc-form-field>-->
            <!--                <sc-form-field :form="form" field="password">-->
            <!--                    <input v-model="form.fields.password" type="password" required/>-->
            <!--                </sc-form-field>-->
            <!--                <sc-button color="secondary" :running="form.processing" type="submit" @clicked="post()">Submit-->
            <!--                </sc-button>-->
            <!--            </form>-->
        </div>
    </div>
</template>

<script>
export default {
  name: 'new',

  data() {
    return {
      form: this.$sform.createForm({
        fields: {
          name: '',
          url: '',
          virtual_folder: {
            default: 'ContentManager',
            description: 'Base Url, without the "/ContentManager"'
          },
          user_name: '',
          password: '',
        },
        url: `content-manager`
      }),
    }
  },

  methods: {
    post() {
      this.form.post()
        .then(res => {
          this.$router.push(`/content-managers/${res.data.id}/edit`)
        })
    }
  }

}
</script>