<template>
    <div>
        <div>
            <sc-tabs class="width-container pd">
                <sc-tab :current="current" name="main" icon="info" to="main"></sc-tab>
                <sc-tab :current="current" name="configs" icon="list" to="configs"></sc-tab>
            </sc-tabs>
        </div>
        <div>
            <children-route :animate="true"></children-route>
        </div>
    </div>
</template>

<script>

export default {
  name: 'index',

  computed: {
    current() {
      return this.$route.meta.name;
    }
  },
}
</script>
