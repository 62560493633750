<template>
    <div id="app">
        <notification-panel></notification-panel>
        <router-view></router-view>
    </div>
</template>

<script>
import ChildrenRoute from "@/components/common/children-route";

export default {
    name: 'root',
    components: {ChildrenRoute},
}
</script>

<style lang="scss">
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../design/fonts/montserrat-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../design/fonts/montserrat-v24-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('../design/fonts/montserrat-v24-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
}

@import '@/design/_reset';
@import '@/design/_globals';


#app {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    position: relative;
}
</style>
