<template>
    <div class="pd">
        <modal-datepicker v-if="isModalVisible" @closeIt="closeModal" :date.sync="dateSingle" @actionButtonClick="exportData" :running="exporting"/>

        <div class="page-header">
            <sc-datepicker :date-range.sync="dateRange"/>
            <sc-button @click="showModal" icon="file-export" :running="exporting">
                Export
            </sc-button>
        </div>
        <div class="pdh">
            <chart-interactions :date-range="dateRange" :dataSets="interactionsData"/>
        </div>
        <div class="top-charts">
            <chart-top-players :topPlayers="topPlayers" :columns="topPlayerColumns" :clickable="true"
                               :loading="topPlayersLoading"
                               @view="viewPlayer" @amountChanged="amountChanged('topPlayersAmount', $event)"/>
            <chart-top-media :topMedia="topMedia" :columns="topMediaColumns" :clickable="false"
                             :loading="topMediaLoading"
                             @amountChanged="amountChanged('topMediaAmount', $event)"/>
        </div>
    </div>
</template>

<script>
import ChartInteractions from "@/components/chart-interactions";
import moment from "moment-timezone";
import ScDatepicker from "@/components/sc-datepicker";
import ChartTopPlayers from "@/components/chart-top-players";
import ChartTopMedia from "@/components/chart-top-media";
import ScButton from "@/components/common/sc-button";
import ModalDatepicker from "@/components/modal-datepicker";

export default {
    name: "home",
    components: {ScButton, ChartTopMedia, ChartTopPlayers, ScDatepicker, ChartInteractions, ModalDatepicker},

    data() {
        return {
            dateRange: {
                startDate: moment().subtract('1', 'month'),
                endDate: moment(),
            },
            dateSingle: {
                startDate: moment(),
                endDate: moment(),
            } ,

            interactions: undefined,
            interactionsData: [],

            // Top Players chart
            topPlayers: undefined,
            topPlayersAmount: 25,
            topPlayersLoading: 1,
            topPlayerColumns: {
                // player_id: {name: 'id', width: 50},
                name: {fill: true},
                total: {width: 150}
            },

            // Top Media chart
            topMedia: undefined,
            topMediaAmount: 25,
            topMediaLoading: 1,
            topMediaColumns: {
                // media_id: {name: 'id', width: 50},
                name: {fill: true},
                total: {width: 150}
            },

            //Export
            exporting: false,
            isModalVisible: false
        }
    },

    watch: {
        dateRange() {
            this.fetch();
        }
    },

    mounted() {
        // this.fetch();
        this.fetchInteractions();
    },

    methods: {
        amountChanged(key, amount) {
            this[key] = amount;
            if (key.toLowerCase().includes('players')) {
                this.fetchTopPlayers();
            } else if (key.toLowerCase().includes('media')) {
                this.fetchTopMedia()
            }
        },

        fetch() {
            this.fetchInteractions();
            this.fetchTopPlayers();
            this.fetchTopMedia();
        },

        exportData() {
            if (!this.exporting) {
                this.exporting = true;

                this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vc/reports/export`, {
                    start: moment.tz(this.dateSingle.startDate, 'UTC').startOf('day'),
                    end: moment.tz(this.dateSingle.endDate, 'UTC').endOf('day')
                }, {
                    responseType: 'blob'
                })
                    .then((res) => {
                        let filename = 'export-playback-vc_log' + '_' + moment().format('Y-MM-DD_HHmm') + '.xlsx';
                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        this.$notify.success('Export complete');

                        this.exporting = false;
                    })
                    .catch((e) => {
                        console.error(e);
                        this.exporting = false;
                    })
            }
        },
        /*
         * INTERACTIONS
         */
        fetchInteractions() {
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day')
            };

            this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vc/reports/playback-events`, payload)
                .then(res => {
                    this.interactions = res.data;
                    this.processInteractions();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        processInteractions() {
            this.interactionsData = [];

            let interactionSets = [
                {
                    name: 'amount',
                    color: '#ff4800',
                    icon: 'times',
                    active: true,
                },
            ]

            for (let i = 0; i < interactionSets.length; i++) {

                let setName = interactionSets[i].name;
                let data = [];

                for (let x = 0; x < this.interactions.length; x++) {
                    let record = this.interactions[x];
                    let startedAt = record.started_at.split('/').reverse().join('/')
                    let dataPoint = {
                        x: moment(startedAt),
                        y: record.amount
                    };
                    data.push(dataPoint);
                }

                let dataSet = {
                    label: setName,
                    data: data,
                    backgroundColor: interactionSets[i].color,
                    borderColor: interactionSets[i].color,
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1,
                };
                this.interactionsData.push(dataSet);
            }
        },

        /*
         * TOP PLAYERS
         */
        fetchTopPlayers() {
            this.topPlayersLoading = 1;
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day'),
                amount: this.topPlayersAmount
            };

            this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vc/reports/top-players`, payload)
                .then(res => {
                    this.topPlayers = res.data;
                    this.topPlayersLoading = 0;
                })
                .catch((e) => {
                    console.error(e)
                    this.topPlayersLoading = 0;
                })
        },

        viewPlayer(player) {
            this.$router.push(`/vc/player/${player.player_id}?name=${player.name}`)
        },

        /*
         * TOP MEDIA
         */
        fetchTopMedia() {
            this.topMediaLoading = 1;
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day'),
                amount: this.topMediaAmount
            };

            if (this.player_id) {
                payload['player_id'] = this.player_id;
            }

            this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vc/reports/top-media`, payload)
                .then(res => {
                    this.topMedia = res.data;
                    this.topMediaLoading = 0;
                })
                .catch((e) => {
                    console.error(e)
                    this.topMediaLoading = 0;
                })
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
    }
}
</script>


<style lang="scss" scoped>
.page-header {
    display: flex;
    justify-content: space-between;

    .button {
        cursor: pointer;
    }
}

.top-charts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
}
</style>
