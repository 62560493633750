<template>
    <div class="pdw">
        <div class="pdh">
            <sc-datepicker :date-range.sync="dateRange"></sc-datepicker>
        </div>
        <chart-interactions :date-range="dateRange" :dataSets="interactionsData"/>
        <div class="pdh" style="max-width:800px">
            <chart-top-media :topMedia="topMedia" :columns="topMediaColumns" :clickable="false"
                             :loading="topMediaLoading"
                             @amountChanged="amountChanged('topMediaAmount', $event)"/>
        </div>
    </div>
</template>

<script>
import ChartInteractions from "@/components/chart-interactions";
import moment from "moment-timezone";
import ScDatepicker from "@/components/sc-datepicker";
import ChartTopMedia from "@/components/chart-top-media";

export default {
    name: "interactions",
    components: {ChartTopMedia, ScDatepicker, ChartInteractions},

    data() {
        return {
            dateRange: {
                startDate: moment().subtract('1', 'month'),
                endDate: moment(),
            },

            interactions: undefined,
            interactionsData: [],

            // Top Media chart
            topMedia: undefined,
            topMediaAmount: 25,
            topMediaLoading: 1,
            topMediaColumns: {
                media_id: {name: 'id', width: 50},
                name: {fill: true},
                total: {width: 150}
            },
        }
    },
    watch: {
        dateRange() {
            this.fetchInteractions();
            this.fetchMedia();
        }
    },

    mounted() {
        this.fetchInteractions();
    },

    methods: {
        amountChanged(key, amount) {
            this[key] = amount;
            this.fetchMedia();
        },

        /*
        * INTERACTIONS
        */
        fetchInteractions() {
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day'),
                player_id: this.$route.params.player_id,
            };

            this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vc/reports/playback-events`, payload)
                .then(res => {
                    this.interactions = res.data;
                    this.processInteractions();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        processInteractions() {
            this.interactionsData = [];

            let interactionSets = [
                {
                    name: 'amount',
                    color: '#ff4800',
                    icon: 'times',
                    active: true,
                },
            ]

            for (let i = 0; i < interactionSets.length; i++) {

                let setName = interactionSets[i].name;
                let data = [];

                for (let x = 0; x < this.interactions.length; x++) {
                    let record = this.interactions[x];
                    let startedAt = record.started_at.split('/').reverse().join('/')
                    let dataPoint = {
                        x: moment(startedAt),
                        y: record.amount
                    };
                    data.push(dataPoint);
                }

                let dataSet = {
                    label: setName,
                    data: data,
                    backgroundColor: interactionSets[i].color,
                    borderColor: interactionSets[i].color,
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1,
                };
                this.interactionsData.push(dataSet);
            }
        },

        /*
        * MEDIA
        */
        fetchMedia() {
            this.topMediaLoading = 1;
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day'),
                player_id: this.$route.params.player_id,
                amount: this.topMediaAmount
            };

            this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vc/reports/top-media`, payload)
                .then(res => {
                    this.topMedia = res.data;
                    this.topMediaLoading = 0;
                })
                .catch((e) => {
                    console.error(e)
                    this.topMediaLoading = 0;
                })
        }
    }
}
</script>
