import index from './index.vue'
import all from './all.vue'
import create from './new.vue'
import edit from './edit.vue'

const routes = {
    path: 'lessons',
    component: index,
    meta: {name: 'lessons'},
    children: [
        {
            path: '',
            component: all,
        },
        {
            path: 'new',
            component: create,
        },
        {
            path: ':lesson_id/edit',
            component: edit
        }
    ]
};

export default routes;
