<template>
    <div>
        <div class="inline-buttons pd pdb0">
            <sc-button icon="plus" @click="$router.push('/lessons/new')">new lesson</sc-button>
        </div>
        <sc-table class="width-container" :table="table" @view="view">
            <template #column-actions="{row}">
                <sc-table-cell :table="table" field="actions" :padded="false" :clickable="false">
                    <template #actions>
                        <sc-table-button icon="edit" @click.native="edit(row)" />
                        <sc-table-button-delete icon="trash" @destroy="deleteItem(row)" style="margin-left:0.5em;"/>

                    </template>
                </sc-table-cell>
            </template>
        </sc-table>
    </div>
</template>

<script>

export default {
    name: "all",
    data() {
        return {
            table: this.$stable.createTable({
                url: '/lessons',
                sortBy: 'name',
                columns: {
                    id: {width: 60},
                    name: {fill: true},
                    actions: {
                        align: 'right'
                    }
                },
            }),
        }
    },

    mounted() {
        this.table.fetch();
    },

    methods: {
        view(item) {
            //
        },
        edit(item) {
            this.$router.push({path: `/lessons/${item.id}/edit`, append: true})
        },
        deleteItem(item) {
            this.$talker.api.delete(`/lessons/${item.id}`)
                .then((res) => {
                    this.$notify.success('Lesson deleted');
                    this.table.fetch();
                })
                .catch((err) => {
                    this.$notify.error('Something went wrong');
                })
        }
    }
}
</script>

<style scoped>

</style>
