<template>
    <div class="width-container">
        <sc-table :table="table" style="padding-bottom:0">

            <template #column-actions="{row}">
                <sc-table-cell :table="table" field="actions" :padded="false" :clickable="false">
                    <template #actions>
                        <sc-table-button-delete icon="trash" @destroy="destroy(row)">
                            <div>You are about to delete an item</div>
                            <br/>
                            <div class="delete-row">Playlist ID: {{ row.playlist_id }}</div>
                            <div class="delete-row">Channel ID: {{ row.channel_id }}</div>
                            <div class="delete-row">Language: {{ row.language }}</div>
                            <br/>
                            <div>Are you sure?</div>
                        </sc-table-button-delete>
                    </template>
                </sc-table-cell>
            </template>

        </sc-table>

        <div class="pd">
            <sc-form :form="form" @submit="submit()">

                <!--                <sc-form-field :form="form" field="playlist_id"></sc-form-field>-->

            </sc-form>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      table: this.$stable.createTable({
        url: `content-manager/${this.$route.params.contentmanager_id}/configs`,
        columns: {
          playlist_id: {},
          channel_id: {},
          language: {fill: true},
          created_at: {type: 'date', name: 'added'},
          actions: {width: 150}
        }
      }),
      form: this.$sform.createForm({
        name: 'Add new config',
        url: `content-manager/${this.$route.params.contentmanager_id}/configs`,
        fields: {
          playlist_id: '',
          channel_id: '',
          language: '',
        }
      })
    }
  },

  mounted() {
    this.table.fetch()
  },

  methods: {
    destroy(row) {
      this.$talker.api.delete(`content-manager/${this.$route.params.contentmanager_id}/configs/${row.id}`)
        .then(res => {
          this.table.fetch();
        })
    },

    submit() {
      this.form.post()
        .then(res => {
          this.table.fetch();
        })
    }
  }


}
</script>

<style scoped lang="scss">
::v-deep .form-content {
    display: flex;

    .row {
        flex: 1;
    }
}

.delete-row {
    font-weight: 500;
    font-size: 0.9em;
    margin: 0.3em 0;
}
</style>