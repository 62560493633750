var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"buttons width-container pd pdb0"},[_c('sc-button',{attrs:{"icon":"plus"},on:{"click":function($event){_vm.generateCodes = true}}},[_vm._v(" Generate new batch (Excel) ")])],1),_c('div',{staticClass:"width-container"},[_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-assigned",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"assigned","clickable":false}},[(row.occupied)?_c('awesome-icon',{attrs:{"icon":"check"}}):_vm._e()],1)]}},{key:"column-KP",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"KP","clickable":false}},[_vm._v(" "+_vm._s(_vm.getPlayerMetaValue(row, 'kp_number'))+" ")])]}},{key:"column-club_name",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"club_name","clickable":false}},[_vm._v(" "+_vm._s(_vm.getPlayerMetaValue(row, 'Club_Name'))+" ")])]}},{key:"column-actions",fn:function(ref){
var row = ref.row;
return [(row.player)?_c('sc-table-cell',{attrs:{"field":"actions","table":_vm.table,"padded":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('sc-table-button',{staticStyle:{"margin-right":"0.5em"},attrs:{"icon":"eye"},on:{"click":function($event){return _vm.view(row)}}}),_c('sc-table-button-confirm',{attrs:{"icon":"unlink","action":"detach"},on:{"onClick":function($event){return _vm.detach(row)}}})]},proxy:true}],null,true)}):_vm._e()]}}])})],1),(_vm.generateCodes)?_c('sc-modal',{attrs:{"title":"Generate new batch (Excel)"},on:{"close":function($event){_vm.generateCodes = false}}},[_c('label',[_vm._v(" Amount to generate "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amountToGenerate),expression:"amountToGenerate"}],attrs:{"type":"number","min":"1","max":"500","step":"1"},domProps:{"value":(_vm.amountToGenerate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amountToGenerate=$event.target.value}}})]),_c('br'),_c('br'),_c('sc-button',{attrs:{"disabled":!_vm.amountToGenerate || _vm.amountToGenerate > 500,"running":_vm.processing,"icon":"download","color":"blue"},on:{"click":_vm.generateBatch}},[(!_vm.processing)?_c('span',[_vm._v(" Generate batch & download ")]):_c('span',[_vm._v(" Generating codes... ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }