<template>
    <div class="pdw">
        <div class="pdh">
            <sc-datepicker :date-range.sync="dateRange"></sc-datepicker>
        </div>
        <chart-interactions :date-range="dateRange" :dataSets="interactionsData"/>
        <div class="pdh" style="max-width:800px" v-if="playerMetricsId">
            <chart-top-media :topMedia="topMedia" :columns="topMediaColumns" :clickable="false"
                             :loading="topMediaLoading"
                             @amountChanged="amountChanged('topMediaAmount', $event)"/>
        </div>
    </div>
</template>

<script>
import ChartInteractions from "@/components/chart-interactions";
import moment from "moment-timezone";
import ScDatepicker from "@/components/sc-datepicker";
import ChartTopMedia from "@/components/chart-top-media";

export default {
    name: "interactions",
    components: {ChartTopMedia, ScDatepicker, ChartInteractions},
    props: {
        passed: {}
    },

    data() {
        return {
            playerMetricsId: undefined,

            dateRange: {
                startDate: moment().subtract('1', 'month'),
                endDate: moment(),
            },

            // Interactions chart
            mediaInteractions: undefined,
            scanInteractions: undefined,
            interactionsData: [],

            // Top Media chart
            topMedia: undefined,
            topMediaAmount: 25,
            topMediaLoading: 1,
            topMediaColumns: {
                media_id: {name: 'id', width: 50},
                name: {fill: true},
                total: {width: 150}
            },
        }
    },
    watch: {
        dateRange() {
            this.fetch();
        }
    },
    mounted() {
        // Fetch player metrics ID from available UUID.
        this.$axios.get(`${process.env.VUE_APP_METRICS_API_URL}/api/players/${this.$route.params.player_id}`)
            .then((res) => {
                this.playerMetricsId = res.data.id;
                this.fetchInteractions();
            })
            .catch((err) => {
                console.error(err);
                this.$notify.error("Something went wrong, please try again later.");
            })
    },
    methods: {
        amountChanged(key, amount) {
            this[key] = amount;
            if (key.toLowerCase().includes('media')) {
                this.fetchTopMedia()
            }
        },

        fetch() {
            this.fetchInteractions();
            this.fetchTopMedia();
        },

        /*
         * INTERACTIONS
         */
        fetchInteractions() {
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day'),
                player_id: this.playerMetricsId,
                amount: this.topMediaAmount
            };

            // Get All interactions
            return Promise.allSettled([this.fetchMediaInteractions(payload), this.fetchScanInteractions(payload)])
                .then(() => {
                    this.processInteractions()
                })
                .catch((error) => {
                    console.error(error);
                })
        },

        async fetchMediaInteractions(payload) {
            await this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/playback-events`, payload)
                .then(res => {
                    this.mediaInteractions = res.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchScanInteractions(payload) {
            // Get scan interactions
            await this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/interactions`, payload)
                .then(res => {
                    this.scanInteractions = res.data;
                })
                .catch((error) => {
                    throw error;
                });
        },


        processInteractions() {
            this.interactionsData = [];

            let interactionSets = [
                {
                    name: 'userScan',
                    color: '#ff4800',
                    icon: 'times',
                    active: true,
                },
                {
                    name: 'userPlayMedia',
                    color: '#4e73e2',
                    icon: 'times',
                    active: true,
                },
            ]

            for (let i = 0; i < interactionSets.length; i++) {

                let setName = interactionSets[i].name;
                let currentInteractions = setName === 'userScan' ? this.scanInteractions : this.mediaInteractions
                let data = [];

                for (let x = 0; x < currentInteractions.length; x++) {
                    let record = currentInteractions[x];
                    let startedAt = record.started_at.split('/').reverse().join('/')
                    let dataPoint = {
                        x: moment(startedAt),
                        y: record.amount
                    };
                    data.push(dataPoint);
                }

                let dataSet = {
                    label: setName,
                    data: data,
                    backgroundColor: interactionSets[i].color,
                    borderColor: interactionSets[i].color,
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1,
                };
                this.interactionsData.push(dataSet);
            }
        },

        /*
             * TOP MEDIA
             */
        fetchTopMedia() {
            this.topMediaLoading = 1;
            let payload = {
                start: moment.tz(this.dateRange.startDate, 'UTC').startOf('day'),
                end: moment.tz(this.dateRange.endDate, 'UTC').endOf('day'),
                player_id: this.playerMetricsId,
                amount: this.topMediaAmount
            };

            this.$axios.post(`${process.env.VUE_APP_METRICS_API_URL}/api/vw/reports/top-media`, payload)
                .then(res => {
                    this.topMedia = res.data;
                    this.topMediaLoading = 0;
                })
                .catch((e) => {
                    console.error(e)
                    this.topMediaLoading = 0;
                })
        }
    }

}
</script>
