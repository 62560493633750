<template>
    <sc-table style="padding:0;" :table="table" :clickable="clickable" :loading="loading">
        <template #header>
            <div class="table-header custom-header">
                {{ table.name }}
                <amount-selector ident="top-media" @changed="$emit('amountChanged', $event)" />
            </div>
        </template>
    </sc-table>
</template>

<script>
import moment from "moment-timezone";

export default {
    name: "chart-top-media",

    props: {
        topMedia: {},
        columns: {required: true},
        clickable: {required: true, type: Boolean},
        loading: {required: false, default: 0, type: Number}
    },

    data() {
        return {
            table: this.$stable.createTable({
                name: 'top media',
                sortBy: 'total',
                columns: this.columns
            })
        }
    },

    watch: {
        topMedia(newValue, oldValue) {
            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                this.update();
            }
        }
    },

    mounted() {
        this.update();
    },

    methods: {
        update() {
            if (this.topMedia) {
                this.table.data = this.topMedia;
                this.table.hasData = true;
                this.table.prefetch();
            }
        },

        view(row) {
            this.$emit('view', row.player_id)
        },

    }
}
</script>

<style scoped lang="scss">
::v-deep .total .top {
    font-weight: 700;
}

.custom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
