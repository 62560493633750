<template>
    <modal @close="closeModal">
        <template v-slot:header>Export data</template>
        <template v-slot:body>
            <p>Choose date</p>
            <sc-datepicker-single :date.sync="pickedDate"/>
        </template>
        <template v-slot:footer-button>
            <sc-button @click="actionButtonClick" icon="file-export" :running="running">Export data</sc-button>
        </template>
    </modal>
</template>

<script>
import modal from "@/components/modal";
import ScDatepickerSingle from "@/components/sc-datepicker-single";
import moment from 'moment-timezone'
import ScButton from "@/components/common/sc-button";

export default {
    name: "modal-datepicker",
    components: {modal, ScDatepickerSingle, ScButton},

    props: {
        date: {
            default: () => {
                return {
                    startDate: moment().format('LL'),
                    endDate: moment().format('LL'),
                }
            }
        },
        running: {
            required: false, type: Boolean, default: false
        }
    },

    computed: {
        pickedDate: {
            get() {
                return this.date;
            },
            set(value) {
                this.$emit('update:date', value)
            }
        },
    },
    methods: {
        closeModal() {
            this.$emit('closeIt')
        },
        actionButtonClick() {
            this.$emit('actionButtonClick');
        }
    },
}
</script>

<style scoped>

</style>
