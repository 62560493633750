<template>
    <div>
        <div class="buttons width-container pd pdb0">
            <sc-button icon="chevron-left" class="back" @click="$router.push('/lessons')">Back
            </sc-button>
        </div>
        <div class="pd width-container">
            <sc-form @submit="post()" :form="form">
            </sc-form>
        </div>
    </div>
</template>

<script>

export default {
    name: 'new',
    data() {
        return {
            form: this.$sform.createForm({
                name: 'New Lesson',
                fields: {
                    name: ''
                },
                url: `lessons`,
                success: () => {
                    this.$notify.success('lesson added')
                    this.$router.push('/lessons')
                }
            }),

        }
    },

    mounted() {

    },

    methods: {
        post() {
            this.form.post()
        }
    }
}
</script>

<style scoped>
.notify {
    padding: 0em 0 2em;
    font-weight: 500;
    font-size: 0.9em;
}
</style>
