const user = {
  namespaced: true,

  state: {
    user: {},
  },

  actions: {

    getUser({state, commit, getters, dispatch}, {talker}) {
      return talker.api.get('users/mine')
        .then(res => {
          commit('SET_USER', {user: res.data});
          return res;
        })
    },

  },

  mutations: {

    SET_USER(state, {user}) {
      state.user = user;
    },

  },

  getters: {

    profile: (state) => {
      if (state.user && state.user.attributes) {
        return state.user.attributes
      }
    },

    isAtLeast: (state) => (hierarchy) => {
      if (!state.user || !state.user.role) {
        return false;
      }
      if (state.user.role.hierarchy <= hierarchy) {
        return true;
      } else {
        return false;
      }
    },
  },

};

export default user;