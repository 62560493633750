import index from './index.vue'
import home from './home.vue'
import player from './player/routes'

const routes = {
    path: '/vc',
    component: index,
    children: [
        {
            path: '',
            component: home,
            meta: {
                name: 'interactions'
            }
        },
        player
    ]
};

export default routes;
