import index from './index.vue'
import all from './all.vue'
import create from './new.vue'

const routes = {
  path: 'users',
  component: index,
  meta: {name: 'users'},
  children: [
    {
      path: '',
      component: all,
    },
    {
      path: 'new',
      component: create,
    },
  ]
};

export default routes;
