import axios from 'axios'

export default {
  install(Vue, params) {
    Vue.prototype.$talker = new Talker(params)
  }
};

class Talker {
  constructor({baseURL = 'https://www.example.com', client_secret = 'my dirty little secret', client_id = 1, grant_type = 'password', cookie = 'bearer'}) {
    this.baseURL = baseURL;
    this.client_secret = client_secret;
    this.grant_type = grant_type;
    this.client_id = client_id;
    this.cookie = cookie;

    this.api = axios.create({
      baseURL: baseURL + '/api',
    });

    if (localStorage.getItem(cookie)) {
      this.api.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(cookie);
    }
  }

  login({username, password}) {

    return Promise.resolve()
      .then(() => {
        return new Promise((resolve, reject) => {
          // reject('test');
          let credentials = {
            ...(username ? {username} : reject('no username')),
            ...(password ? {password} : reject('no password')),
            ...(this.client_secret ? {client_secret: this.client_secret} : reject('no client secret')),
            ...(this.client_id ? {client_id: this.client_id} : reject('no client id')),
            ...(this.grant_type ? {grant_type: this.grant_type} : reject('no grant type')),
          };
          return resolve(credentials)
        });
      })
      .then(credentials => {
        return new Promise((resolve, reject) => {
          axios.post(this.baseURL + '/oauth/token', credentials)
            .then(res => {

              if (res.data.access_token) {
                localStorage.setItem(this.cookie, res.data.access_token);
                this.api.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
                return resolve()
              } else {
                return reject('No access token received')
              }
            })
            .catch(error => {
              this.clearAuthorization();
              if (error.response && error.response.data) {
                reject(error.response.data);
              } else {
                reject({message: 'Failed to login'})
              }
            });
        });
      })

  }

  logout({router}) {
    return Promise.resolve()
      .then(() => {
        this.clearAuthorization();
      })
      .then(() => {
        if (router && router.history.current.name !== 'login') {
          router.push('/login')
        }
      })
  }

  reAuthenticate({username, password}) {
    return Promise.resolve()
      .then(() => {
        this.clearAuthorization();
      })
      .then(() => {
        return this.login({username, password})
      })
      .catch(err => {
        this.cError('failed to re-authenticate:', err);
        throw(err);
      })
  }

  clearAuthorization() {
    this.api.defaults.headers.common['Authorization'] = null;
    if (localStorage.getItem(this.cookie)) {
      localStorage.removeItem(this.cookie);
    }
  }

  cError(...message) {
    let title = '%cTalker error';
    let design = "background:#aa0000;color:white;display:block;padding:0.5em 1em;";
    let test = this.baseURL;
    console.error(title, design, ...message);
  }
}